body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  margin: 0;
  padding: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

/*

Style our app according to the provided design!

green color -> #5f9341

*/


input {
  display: block;
  width:95%;
  padding:10px 5px 10px 5px ;
  box-sizing: border-box;
  border: 1px solid #5f9341;
}

button {
  margin-top:5px;
  padding: 10px 25px 10px 25px;
  color: white;
  background: #5f9341;
  border:none;
  font-weight: bold;
  cursor: pointer;
  font-size: 18px;
}
ul{
  margin-top: 1.8em;
  margin-left: -2.4em;
  list-style: none;
}
li{
  margin-top: 0.5em;


}
a{
  color: #5f9341;
 

}